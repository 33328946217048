/* eslint-disable react/no-array-index-key, no-param-reassign, @typescript-eslint/no-use-before-define */
import Head from 'next/head'
import { GetServerSidePropsContext } from 'next'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react-lite'
import { H1 } from '@/components/Typography'
import { withTranslations } from '@/utils/withTranslations'
import { useRootStore } from '@/stores/RootStoreProvider'
import React from 'react'
import { useRouter } from 'next/router'
import fetchStreams from '@/services/fetchStreams'
import { logger } from '@/services/logging'
import { useColumns } from '@/utils/hooks/useColumns'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { Stream } from '@/services/api.types'
import { RootStoreHydration } from '@/stores/RootStore'
import StreamList from '@/components/StreamList'

export default observer(() => {
  const router = useRouter()
  const { t } = useTranslation()
  const store = useRootStore()
  const { streams, playingStream, playing } = store

  const timeoutRef = React.useRef<NodeJS.Timeout>(null)
  const numColumns = useColumns()

  const getStreams = async () => {
    const timeout = await store.makeRequest()
    timeoutRef.current = queueRequest(Math.max(1000, timeout))
  }

  const queueRequest = (timeout) => setTimeout(getStreams, Math.min(15000, timeout))

  React.useEffect(() => {
    getStreams()
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  React.useEffect(() => {
    store.setSelectedStreamId(null)
    if (!streams || streams.length === 0) {
      console.info('fetching channels...')
      ;(async () => {
        await store.fetchChannels()
      })()
    }
  }, [])

  const getTitle = () =>
    playingStream
      ? `${t('title')} — ${playingStream.displayName} — ${playingStream?.current?.title || t('defaultArtist')}`
      : t('title')

  const handleClick = (streamId: string, _streamName?: string) => async () => {
    await router.push(`/${streamId}`)
  }

  return (
    <div className="flex w-full">
      <Head>
        <title>{getTitle()}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={t('metaDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <div className="flex flex-1 flex-col">
        <div className="flex justify-center items-center pb-6 xs:pb-4">
          <H1 className="font-black">{t('selectChannel')}</H1>
        </div>
        <div className="flex justify-center items-center pb-6">
          <div className="flex flow-row">
            <StreamList
              streams={streams}
              streamId={playingStream?.id}
              playing={playing}
              onClick={handleClick}
              columns={numColumns}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const selectors = getSelectorsByUserAgent(context.req.headers['user-agent'])
  let streams: Stream[]
  try {
    streams = (await fetchStreams()).data
  } catch (error) {
    logger.error('Error while fetching list of streams', error)
  }
  return {
    props: {
      ...(await withTranslations(context)),
      hydrationData: {
        streams: streams || [],
        selectors
      } as RootStoreHydration
    }
  }
}
