import React from 'react'
import Vinyl from '@/components/Vinyl'
import Equalizer from '@/components/Equalizer'
import { useTranslation } from 'next-i18next'
import { parseTitle } from '@/utils'
import { usePageVisibility } from 'react-page-visibility'
import styles from './StreamCard.module.scss'

interface Props {
  index?: number
  streamName: string
  title: string
  mediaUrl?: string
  playing?: boolean
  onClick?: () => void
}

export default function StreamCard({ index, streamName, title, playing, mediaUrl, onClick }: Props) {
  const isVisible = usePageVisibility()
  const { t } = useTranslation()
  const { artist, song } = parseTitle(title, t('defaultArtist'), streamName)
  return (
    <div className={styles.cardLayout}>
      <div
        className={`${styles.card} cursor-pointer`}
        tabIndex={index}
        onClick={onClick}
        role="button"
        onKeyPress={onClick}>
        <div className={`${styles.cardBackground} flex-col w-full`}>
          <div>
            <div className="flex flex-col pl-4 pr-14 pt-5">
              <Equalizer equalize={playing && isVisible} className="mb-2" />
              <span className={`truncate overflow-ellipsis max-h-16 ${styles.title}`}>{streamName}</span>
            </div>
            <div className="flex flex-col px-4">
              <span className={`${styles.song} pr-4`}>{song}</span>
              <span className={`${styles.artist} overflow-ellipsis`}>{artist}</span>
            </div>
          </div>
        </div>
        <div className="absolute top-6 bottom-0 right-12">
          <div className="relative flex">
            <Vinyl
              rotate={playing && isVisible}
              backgroundUrl={mediaUrl}
              className="absolute left-0 right-0 top-0 bottom-0"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
