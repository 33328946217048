import React from 'react'
import styles from './Equalizer.module.scss'

interface Props {
  equalize: boolean
  className?: string
}

export default function Equalizer({ equalize, className }: Props) {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`flex flex-col ${equalize ? styles.equalizing : ''}`}>
        <div className={`${styles.line} ${equalize ? styles.equalize1 : ''}`} />
        <div className={`${styles.line} ${equalize ? styles.equalize2 : ''}`} />
        <div className={`${styles.line} ${equalize ? styles.equalize3 : ''}`} />
      </div>
    </div>
  )
}
