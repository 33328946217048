/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Stream } from '@/services/api.types'
import StreamCard from '@/components/StreamCard'
import { chunk } from '@/utils'

const StreamList: React.FC<{
  streams: Stream[]
  streamId: string
  playing: boolean
  onClick: (streamId: string, streamName?: string) => () => void
  columns: number
}> = React.memo(({ streams, streamId, playing, onClick, columns }) => {
  const elements = chunk(streams, columns).map((chunk, index) => (
    <div key={`column-${index}`}>
      {chunk.map((stream: Stream, index) => (
        <div className="p-4 sm:p-2 xs:p-2" key={stream.id}>
          <StreamCard
            index={index}
            playing={streamId === stream.id && playing}
            streamName={stream.displayName}
            title={stream.current?.title}
            mediaUrl={`/images/${stream.id}.png`}
            onClick={onClick(stream.id, stream.displayName)}
          />
        </div>
      ))}
    </div>
  ))
  return <>{elements}</>
})
export default StreamList
