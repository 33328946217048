import React from 'react'
import Image from 'next/future/image'
import styles from './Vinyl.module.scss'

interface Props {
  backgroundUrl: string
  rotate: boolean
  className?: string
}

export default function Vinyl({ backgroundUrl, rotate, className = '' }: Props) {
  return (
    <div className="relative flex">
      <div className={`${styles.vinyl} ${rotate ? styles.rotate : ''} ${className}`}>
        <Image width={128} height={128} quality={90} src={backgroundUrl} alt="Vinyl Disk" className={styles.image} />
        <div className={`${styles.withBorder} absolute bottom-0 left-0 right-0 top-0`} />
        <div className={styles.vinylCenter} />
        <div className={styles.vinylCenter2} />
      </div>
    </div>
  )
}
