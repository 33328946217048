import { useWindowSize } from '@kyivstarteam/ks-react-design-system-public/Hooks'

export function useColumns() {
  const { width } = useWindowSize()
  if (width === undefined) return 2
  let columns: number
  if (width < 640) {
    columns = 2
  } else if (width >= 640 && width <= 768) {
    columns = 3
  } else if (width > 768 && width <= 1024) {
    columns = 4
  } else {
    columns = 5
  }
  return columns
}
